(() => {
    let tabs = document.querySelectorAll("#cohort--selector li .tab");
    let panels = document.querySelectorAll("#cohort--panels .tab-panel");
    let title = document.getElementById("pre-typewriter");
    let typewriter = document.getElementById("typewriter");
    let isReducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)").matches;

    const type = (text) => {
        let lowercase = text?.charAt(0).toLowerCase();
        title.innerHTML = lowercase === 'a' || lowercase === 'e' || lowercase === 'i' || lowercase === 'o' || lowercase === 'u'  ? "I am an " : "I am a ";
        typewriter.innerHTML = '';
        typewriter.classList.add('typing');

        writeLetter(0, text, isReducedMotion ? 0 : 100);
    }

    const writeLetter = function(i, text, speed){
        if(i < text.length){
            typewriter.innerHTML += text.charAt(i);
            setTimeout(() => writeLetter(i + 1, text, speed), speed);
        }
        else {
            typewriter.classList.add('blink');

            setTimeout(() => {
                typewriter.classList.remove('typing');
                typewriter.classList.remove('blick');
            }, isReducedMotion ? 0 : 4000);
        }
    }

    tabs.forEach((tab, index) => {
        tab.addEventListener('click', () => {
            tabs.forEach((tab) => {
                tab.disabled = true;
                tab.classList.remove("active");
                tab.setAttribute("aria-selected", 'false');
            });

            type(tab.dataset.text);

            const hidePanel = new Promise((resolve, reject) => {
                panels.forEach((panel) => {
                    if(panel.classList.contains('show') || panel.classList.contains('active')) {
                        panel.classList.remove('show');

                        setTimeout(() => {
                            panel.classList.remove('active');
                            resolve();
                        }, isReducedMotion ? 0 : 900);
                    }
                })
            });

            hidePanel.then(() => {
                tab.classList.add('active');
                tab.setAttribute('aria-selected', 'true');

                panels[index].classList.add('active');

                setTimeout(() => {
                    panels[index].classList.add('show');
                    tabs.forEach((tab) => { tab.disabled = false; });
                }, isReducedMotion ? 0 : 200);
            });
        });
    })
})()